import OffLine from '@/components/OffLine';
import OfflinePlaceholder from '@/components/OfflinePlaceholder';
import OnLine from '@/components/OnLine';
import Swiper, { SwiperColumns } from '@/components/Swiper';
import { TeaserTile } from '@/components/TeaserTile';
import { useEffect, useState } from 'react';

export interface NewsTeaserProps {
  amount: number | string;
  category: string;
}

export default function NewsTeaser({ amount, category }: NewsTeaserProps) {
  const [recentArticles, setRecentArticles] = useState();

  useEffect(() => {
    async function fetchData() {
      const responseRecentArticles = await fetch(`/api/data/recent-articles`);
      if (responseRecentArticles.ok) {
        setRecentArticles(await responseRecentArticles.json());
      }
    }
    fetchData();
  }, []);

  function items() {
    const articles = recentArticles ? recentArticles[category] : undefined;
    return articles ? articles.slice(0, +amount) || [] : [];
  }

  function columns(): SwiperColumns {
    return +amount === 3 ? SwiperColumns.Three : SwiperColumns.Two;
  }

  return (
    <div className="NewsTeaser">
      <OffLine>
        <OfflinePlaceholder box={true} />
      </OffLine>
      <OnLine>
        <Swiper columns={columns()}>
          {items().map((item) => (
            <TeaserTile
              key={item.path}
              title={item.title}
              text={item.teaser}
              date={item.date}
              link={item.path}
              image={item.image}
              category={item.category}
            />
          ))}
        </Swiper>
      </OnLine>
    </div>
  );
}
