import classnames from 'classnames';
import React from 'react';
import { useSwipeable } from 'react-swipeable';
import {
  ConfigurationOptions,
  SwipeableCallbacks,
} from 'react-swipeable/es/types';

export enum SwiperColumns {
  Two = 'Swiper--columns-2',
  Three = 'Swiper--columns-3',
  Four = 'Swiper--columns-4',
}

export interface SwiperProps {
  children: JSX.Element[];
  columns?: SwiperColumns;
}

interface SwiperState {
  currentItem: number;
}

type SwipableProps = Partial<SwipeableCallbacks & ConfigurationOptions> & {
  children: React.ReactNode;
  className?: string;
};

export const Swipeable = ({ children, className, ...props }: SwipableProps) => {
  const handlers = useSwipeable(props);
  return (
    <div {...handlers} className={className}>
      {children}
    </div>
  );
};

export class Swiper extends React.Component<SwiperProps, SwiperState> {
  static defaultProps: Partial<SwiperProps> = {
    columns: SwiperColumns.Four,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentItem: 0,
    };
  }

  private static get isMobile(): boolean {
    return window ? window.innerWidth < 576 : false;
  }

  private onSwipedLeft = (): void => {
    this.move(1);
  };

  private onSwipedRight = (): void => {
    this.move(-1);
  };

  private move = (direction: 1 | -1): void => {
    const index = this.state.currentItem + direction;
    const limited = this.limitIndex(index);
    const item = this.getOverswing(index);
    this.set(item, () => {
      setTimeout(() => this.set(limited), 300);
    });
  };

  private handleOnClick = (
    $event: React.MouseEvent<HTMLDivElement>,
    index: number
  ): void => {
    if (index !== this.state.currentItem && Swiper.isMobile) {
      $event.preventDefault();
      this.set(index);
    }
  };

  private set = (index: number, callback: Function = () => void 0): void => {
    this.setState({ currentItem: index }, callback());
  };

  private limitIndex = (value: number): number => {
    return Math.max(0, Math.min(this.length - 1, value));
  };

  private getOverswing = (index: number): number => {
    const limited = this.limitIndex(index);
    if (index > limited) {
      return limited + 0.25;
    }
    if (index < limited) {
      return limited - 0.25;
    }
    return limited;
  };

  private get length(): number {
    return Math.max(this.props.children.length, 1);
  }

  private get widthContainer(): string {
    return `${this.length * 100}%`;
  }

  private get widthItem(): string {
    return `${100 / this.length}%`;
  }

  private get translateX(): string {
    const percent = this.state.currentItem * (100 / this.length);
    return `translateX(${-1 * percent}%)`;
  }

  render(): JSX.Element {
    const { columns, children } = this.props;
    return (
      <Swipeable
        onSwipedLeft={this.onSwipedLeft}
        onSwipedRight={this.onSwipedRight}
        className={classnames('Swiper', columns)}
        delta={0.4}
        preventScrollOnSwipe
      >
        <div
          className="Swiper__container"
          style={{
            width: this.widthContainer,
            transform: this.translateX,
          }}
        >
          {children &&
            children.map((item, i) => (
              <div
                key={i}
                className="Swiper__item"
                onClick={($event) => this.handleOnClick($event, i)}
                style={{ width: this.widthItem }}
              >
                {item}
              </div>
            ))}
        </div>
      </Swipeable>
    );
  }
}

export default Swiper;
