import Logo from '@/components/Logo';
import { LogoVariant } from '@/components/LogoVariant';
import { GameConfig } from '@/config/game';
import { getGameByShortname } from '@/helpers/getGameByShortname';
import classnames from 'classnames';
import React from 'react';

export interface TeaserTileHeaderProps {
  title?: string;
  category: string;
}

export class TeaserTileHeader extends React.Component<TeaserTileHeaderProps> {
  private get config(): GameConfig | undefined {
    return getGameByShortname(this.props.category);
  }

  private get title(): string {
    return this.props.title || 'News';
  }

  private get logo(): LogoVariant {
    const config = this.config;
    return config ? config.logo : LogoVariant.Main;
  }

  private get alt(): string {
    const config = this.config;
    return config ? config.title : 'LOTTO.de';
  }

  render(): JSX.Element {
    return (
      <div
        className={classnames('TeaserTileHeader', {
          [`TeaserTileHeader--${this.props.category}`]:
            typeof this.config !== 'undefined',
        })}
      >
        <strong className="TeaserTileHeader__title">{this.title}</strong>
        <Logo
          className="TeaserTileHeader__logo"
          variant={this.logo}
          title={this.alt}
        />
      </div>
    );
  }
}
